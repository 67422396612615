import { Indicator } from "./src/classes/Indicator";
import { PortfolioModal } from "./src/classes/Modal";

const segindicator = document.getElementById('segment-indicator') as HTMLDivElement;

const indicator = new Indicator();
let secCounter = 0;
setInterval(() => {
    const _currentTime = (new Date().toLocaleString("ru-RU", {timeZone: "Europe/Moscow"})).split(', ');
    const currentTime = _currentTime[1].split(':');
    const [h, min] = [currentTime[0], currentTime[1]];
    indicator.printString(h + min);
    (secCounter % 2 === 0) ? indicator.lightDotON() : indicator.lightDotOFF();
    secCounter += 1;
    if (secCounter > 9)
        secCounter = 0;
}, 500);

segindicator.addEventListener('click', () => {
    indicator.nextColor();
});
 
const perr = document.getElementById('error-message') as HTMLParagraphElement;
perr.textContent = '';
let counter = 0;

const viewMessageFromServer = async (res: {errorsMessages: {message: string; field: string}[]}) => {
    if (perr) {
        perr.textContent = '';
        if (res.errorsMessages.length > 0) {
            let msg = 'Не валидные значения полей: ';
            for (let emsg of res.errorsMessages) {
                msg += ` ${emsg.field} `
            }
            perr.textContent = msg;
            perr.classList.add('errtext');
            counter = 0;
        } else {
            perr.textContent = 'Ваше сообщение отправлено.';
            perr.classList.remove('errtext');
        }
    }
}



const form = document.getElementById("contacts-form") as HTMLFormElement;
const sendButton = document.getElementById("submit") as HTMLButtonElement;


sendButton.addEventListener("click", async (e) => {
    e.preventDefault();
    const formData = new FormData(form);
    const sendData = Object.fromEntries(formData);
    
    try {
        if (counter < 3) {
            const response = await fetch('/sendMessage', {
                method: "POST", 
                mode: "cors",  
                cache: "no-cache",  
                credentials: "include", 
                headers: { 
                    "Content-Type": "application/json",   
                },
                redirect: "follow", 
                referrerPolicy: "no-referrer", 
                body: JSON.stringify(sendData),   
              });
              const resp = await response.json();
              console.log(resp);
              await viewMessageFromServer(resp);
              counter++;
        }

    } catch (err) {
        console.log('------- *** --------', '\n', err, '\n', '------- *** --------');
    }
});

const arrow = document.getElementById("arrow-up-scroll");

const up = document.getElementById('service');
const screenHeight = window.screen.height;
window.addEventListener('scroll', () => {
    if (arrow && up) {
        const thisPosition = window.scrollY;
        const otherPosition = up.getBoundingClientRect();
        if (+thisPosition >= (screenHeight + otherPosition.y)) {
            arrow.classList.add('visible-arrow-scroll');
        } else {
            arrow.classList.remove('visible-arrow-scroll');
        }
    }
});

arrow?.addEventListener('click', () => {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
});

// ------------------------- кнопки "подробнее..." наши работы ----------------------------

const vibroPress = document.getElementById('btn-vibropress') as HTMLButtonElement;
const dosePlace = document.getElementById('dose-place') as HTMLButtonElement;
const modListogib = document.getElementById('modernisation-press-listogib') as HTMLButtonElement;
const ib1330 = document.getElementById('ib1330') as HTMLButtonElement;
const ke2330 = document.getElementById('ke2330') as HTMLButtonElement;
const trubogib = document.getElementById('trubogib') as HTMLButtonElement;
const thermosaw = document.getElementById('thermosaw') as HTMLButtonElement;


vibroPress.addEventListener('click', () => {
    const modal = new PortfolioModal({
        id: 'portfolioModal',
        title: 'Вибропресс для производства тротуарной плитки.',
        body: [
            {
                media: 'image',
                src: 'img/portfolio/plito_3d_press.jpg',
                about: '3D модель вибропресса для производства тротуарной плитки (САПР SolidWorks). Пресс разрабатывался "с нуля". Разработана, продумана и просчитана каждая деталь. '
            },
            {
                media: 'image',
                subtitle: 'Шкаф управления прессом для прессования тротуарной плитки.',
                src: 'img/portfolio/press_electro_2.jpg',
                about: 'Сконструирован новый  шкаф управления прессом для прессования тротуарной плитки. В результате увеличена производительность пресса. '
            },
            {
                media: 'image',
                src: 'img/portfolio/plito_electro_1.jpg',
                about: 'Шкаф управления прессом для прессования тротуарной плитки.'
            },
            {
                media: 'image',
                subtitle: 'Система нагрева пуансонов.',
                src: 'img/portfolio/heat_puansons.png',
                about: 'Разработка и внедрение системы нагрева пуансонов на прессе для производства тротуарной плитки.'
            },
        ]
    });

    modal.visibleModal();
});

dosePlace.addEventListener('click', () => {
    const modal = new PortfolioModal({
        id: 'portfolioModal',
        title: 'Участок весового дозирования сырья',
        body: [
            {
                media: 'image',
                src: 'img/portfolio/dosator_tate.jpg',
                about: 'Автоматизация участка весового дозирования  сырья на три компонента на минском предприятии.'
            },
            {
                media: 'image',
                subtitle: 'Шкаф автоматического весового дозирования сырья',
                src: 'img/portfolio/dosator_electro.jpg',
                about: 'Сконструирован шкаф автоматического  весового   дозирования сырья на 3 компонента  (песок, цемент, вода) для автоматизации участка подготовки сырья прессования плитки тротуарной.'
            },
            {
                media: 'image',
                src: 'img/portfolio/press_el_box_33.jpg',
                about: 'Шкаф автоматического  весового   дозирования сырья.'
            }
        ]
    });
    modal.visibleModal();
});

modListogib.addEventListener('click', () => {
    const modal = new PortfolioModal({
        id: 'portfolioModal',
        title: 'Модернизация пресса-листогиба',
        body: [
            {
                media: 'image',
                src: 'img/portfolio/листогиб.jpg',
                about: 'Модернизация пресса-листогиба на минском предприятии.'
            },
            
        ]
    });
    modal.visibleModal();
});

ib1330.addEventListener('click', () => {
    const modal = new PortfolioModal({
        id: 'portfolioModal',
        title: 'Модернизация пресса-листогиба ИВ1330',
        body: [
            {
                media: 'image',
                src: 'img/portfolio/ИВ1330.jpg',
                about: 'Модернизация шкафа  управления пресса-листогиба ИВ1330 для Белорусского предприятия агротехнического сектора. Устранены проблемы с частыми остановками оборудования на ремонт электронного блока управления.'
            },
            {
                media: 'image',
                subtitle: 'Модернизация шкафа управления прессом-листогибом ИВ1330. ',
                src: 'img/portfolio/ИВ1330._шкаф2.png',
                about: ''
            },
            {
                media: 'image',
                src: 'img/portfolio/ИВ1330._шкаф3.png',
                about: ''
            },
        ]
    });
    modal.visibleModal();
});

ke2330.addEventListener('click', () => {
    const modal = new PortfolioModal({
        id: 'portfolioModal',
        title: 'Модернизация пресса КЕ2330',
        body: [
            {
                media: 'image',
                src: 'img/portfolio/КЕ2330.jpg',
                about: 'Шкаф управления прессом КЕ2330 на Белорусском предприятии машиностроения. Устранены частые простои техпроцесса из-за поломок электрики. Также были установлены новые датчики положения, восстановлен контроль вращения и удержания оборотов электромотора. Добавлены датчики защитных ограждений для безопасности работ и контроль наличия и чередования фаз.'
            },
            {
                media: 'image',
                src: 'img/portfolio/КЕ2330_шкаф3.png',
                about: 'Шкаф управления прессом КЕ2330 на Белорусском предприятии машиностроения.'
            },
            {
                media: 'image',
                src: 'img/portfolio/КЕ2330_шкаф4.png',
                about: 'Шкаф управления прессом КЕ2330 на Белорусском предприятии машиностроения.'
            },
        ]
    });
    modal.visibleModal();
});
 
trubogib.addEventListener('click', () => {
    const modal = new PortfolioModal({
        id: 'portfolioModal',
        title: 'Разработка шкафа управления станком трубогибочным',
        body: [
            {
                media: 'image',
                src: 'img/portfolio/трубогиб1.png',
                about: 'Разработка шкафа управления станком трубогибочным  для минского предприятия. Станок является новой разработкой. Служит для изготовления элементов спиральных теплообменников.'
            },
            {
                media: 'image',
                src: 'img/portfolio/трубогиб1_шкаф.png',
                about: 'Шкаф управления станком трубогибочным.'
            },
            {
                media: 'image',
                src: 'img/portfolio/трубогиб3_шкаф.jpg',
                about: 'Шкаф управления станком трубогибочным.'
            },
        ]
    });
    modal.visibleModal();
});

thermosaw.addEventListener('click', () => {
    const modal = new PortfolioModal({
        id: 'portfolioModal',
        title: 'Термонож ТН-1',
        body: [
            {
                media: 'image',
                src: 'img/portfolio/термонож.png',
                about: 'Термонож ТН-1. Модернизация дискового отрезного устройства линии производства ПВХ панелей. Разработан и установлен в качестве основного узла термонож для поперечного отрезания ПВХ профиля методом отрезания нагретым лезвием. Существенно улучшается качество реза, пропадает проблема мелкой стружки, отсутствуют опилки (нечему попадать под текстурную пленку). '
            },
            {
                media: 'image',
                subtitle: 'Термонож ТН-1. 3D модель режущего узла.',
                src: 'img/portfolio/thermosaw3d.jpg',
                about: ''
            },
            {
                media: 'video',
                subtitle: 'Термонож ТН-1 в работе.',
                src: 'img/portfolio/Европрофиль_Термонож _ТН-1.mp4',
                about: ''
            },
        ]
    });
    modal.visibleModal();
});



