import { DigitCodeIndicator } from "../types/DigitCodeIndicator";
import { ReadySegments } from "../types/ReadySegments";

/**
 * цвета включенного и выкл. индикатора
 */
export enum StatusColorIndicator {
    ON = "fill: rgba(12, 159, 212, 0.925);",
    OFF = "fill: rgba(119, 119, 119, 0.2);",
};

/**
 * Семисегментный индикатор - часы
 */
export class Indicator {
    colors = [
        "fill: rgba(12, 159, 212, 0.925);",
        "fill: rgba(225, 26, 26, 0.925);",
        "fill: rgba(26, 225, 66, 0.925);",
        "fill: rgba(222, 225, 26, 0.925);",
    ];
    offColor = StatusColorIndicator.OFF;
    _onColor = StatusColorIndicator.ON;
    private delimeterDotId = "sym-dot-100";
    private partIdSegmentElements = "sym-seg-";
    private digits = 4;
    private readySegments: ReadySegments = {};
    private digitCodes: DigitCodeIndicator = {
        "1": [0, 0, 0, 1, 1, 0, 0],
        "2": [1, 0, 1, 1, 0, 1, 1],
        "3": [1, 0, 1, 1, 1, 1, 0],
        "4": [1, 1, 0, 1, 1, 0, 0],
        "5": [1, 1, 1, 0, 1, 1, 0],
        "6": [1, 1, 1, 0, 1, 1, 1],
        "7": [0, 0, 1, 1, 1, 0, 0],
        "8": [1, 1, 1, 1, 1, 1, 1],
        "9": [1, 1, 1, 1, 1, 1, 0],
        "0": [0, 1, 1, 1, 1, 1, 1],
        "-": [1, 0, 0, 0, 0, 0, 0],
        "_": [0, 0, 0, 0, 0, 1, 0],
        "a": [1, 0, 1, 0, 1, 0, 1],
        "A": [1, 1, 1, 1, 1, 0, 1],
        "L": [0, 1, 0, 0, 0, 1, 1],
        "t": [1, 1, 0, 0, 0, 1, 1],
        "p": [1, 1, 1, 1, 0, 0, 1],
        "P": [1, 1, 1, 1, 0, 0, 1],
        "I": [0, 1, 0, 0, 0, 0, 1],
        "d": [1, 0, 0, 1, 1, 1, 1],
        "r": [1, 0, 0, 0, 0, 0, 1],
        "E": [1, 1, 1, 0, 0, 1, 1],
        "S": [1, 1, 1, 0, 1, 1, 0],
        "Y": [1, 1, 0, 1, 1, 1, 0],
        "H": [1, 1, 0, 1, 1, 0, 1],
        "u": [0, 0, 0, 0, 1, 1, 1],
        "o": [1, 0, 0, 0, 1, 1, 1],
        "*": [1, 1, 1, 1, 0, 0, 0],
        "c": [1, 0, 0, 0, 0, 1, 1],
        "C": [0, 1, 1, 0, 0, 1, 1],
        "U": [0, 1, 0, 1, 1, 1, 1],
        "y": [1, 1, 0, 1, 0, 1, 1],
        "J": [0, 0, 0, 1, 1, 1, 1],
        "F": [1, 1, 1, 0, 0, 0, 1],
        "n": [1, 0, 0, 0, 1, 0, 1],
        "b": [1, 1, 0, 0, 1, 1, 1],
    };

    constructor () {
        this.makeReadySegments();
    }

    public nextColor(): void {
        const first = this.colors.shift();
        if (first)
            this.colors.push(first);
    } 

    get onColor() {
       return this.colors[0];
    }

    /**
     * объекты сегментов
     * ключ - номер индикатора (с нуля)
     * значение - массив объектов svg сегментов (которые зажигаются)
     */
    private makeReadySegments(): void {
        this.clearReadySegments();
        for (let i = 0; i < this.digits; i += 1) {
            for (let j = 0; j < 7; j += 1) {
                const idSegmentElement = document.getElementById(
                    this.partIdSegmentElements + `${i}-${j}`
                    ) as unknown as SVGPolygonElement;
                this.addSegmentElement(('' + i), idSegmentElement);
            }
        }
    } 

    private addSegmentElement(numberSegment: string, segmentElement: SVGPolygonElement): void {
        if (!this.readySegments.hasOwnProperty(numberSegment))
            this.readySegments[numberSegment] = [];
        this.readySegments[numberSegment].push(segmentElement);
    }

    private clearReadySegments(): void {
        this.readySegments = {};
    }


    /**
     * Печать строки
     * @param str строка для вывода
     */
    public printString(str: string): void {
        const len = str.length;
        let count = this.digits - len;

        this.clearIndicator();
        for (let i = 0; i < len; i += 1, count += 1) {
            this.printSegment(count, str[i]);
        }
    } 


    /**
     * Печать одного символа
     * @param numSegment  - номер сегмента с нуля
     * @param value - значение
     */
    public printSegment(numSegment: number, value: string): void {
        const segment = this.readySegments['' + numSegment];
        let code = this.getDigitCodes(value);
        let counter = 0;
        
        this.clearOneSegment(numSegment);
        for (let seg of code) {
            this.doLightSegment(segment[counter], seg);
            counter++; 
        }
    }

    private getDigitCodes(symbol: string): Array<number> {
        return (this.digitCodes[symbol]) ? this.digitCodes[symbol] : this.digitCodes['_']; 
    }

    private lightON(svgSegment: SVGPolygonElement): void {
        svgSegment.setAttribute('style', `${this.onColor}`);
    }

    private lightOFF(svgSegment: SVGPolygonElement): void {
        svgSegment.setAttribute('style', `${this.offColor}`);
    }

    private doLightSegment(svgSegment: SVGPolygonElement, stateSegment: number): void {
        if (svgSegment) {
            if (stateSegment === 1) {
                this.lightON(svgSegment);
        } else {
                this.lightOFF(svgSegment);
            }
        }
    }

    private clearOneSegment(numSegment: number): void {
        const segment = this.readySegments['' + numSegment];
        for (const s of segment) {
            this.lightOFF(s);
        }
    }


    /**
     * Очистить индикатор
     */
    public clearIndicator(): void {
        for (const indicator of Object.values(this.readySegments)) {
            for (const segment of indicator) {
                if (segment)
                    this.lightOFF(segment);
            }
        }
    }

    public lightDotON(): void {
        const dot = document.getElementById(
            this.delimeterDotId
            ) as unknown as SVGPolygonElement; 
        if (dot) {
            this.lightON(dot);
        }
    }

    public lightDotOFF(): void {
        const dot = document.getElementById(
            this.delimeterDotId
            ) as unknown as SVGPolygonElement; 
        if (dot) {
            this.lightOFF(dot);
        }
    }
}