import 'bootstrap'
import { BodyPortfolioModalData, PortfolioModalData } from '../types/PortfolioModalData';

export abstract class Modal {
    private _header: JQuery<HTMLHeadingElement> = $('#title-portfolio-modal');
    protected modal: JQuery<HTMLElement> | null = null;
    protected body: JQuery<HTMLDivElement> = $('#body-portfolio-modal');
    protected portfolioModalData !: PortfolioModalData;

    constructor(portfolioModalData: PortfolioModalData) {
        this.portfolioModalData = portfolioModalData;
        this.modal = $(`#${portfolioModalData.id}`);
    }

    set header(title: string) {
        this._header.text(title);
    }

    visibleModal() {
        if (this.modal) {
            this.modal.modal('show');
        }
    }

    hiddenModal() {
        if (this.modal) {
            this.modal.modal('hide');
        }
    }

    clearBodyModal() {
        this.body.empty();
    }

    abstract makeBodyModal(): void;
}

// -------------------------------------------------------------

export class PortfolioModal extends Modal {
    constructor (portfolioModalData: PortfolioModalData) {
        super(portfolioModalData);
        this.makeBodyModal();
    }

    makeBodyModal(): void {
        this.header = this.portfolioModalData.title;
        this.clearBodyModal();
        
        for (let val of this.portfolioModalData.body) {
            if (val.media === 'image') { 
                this.appendImage(val);
            } else if (val.media === 'video') {
                this.appendVideo(val);
            }
        }
    }

    private appendImage(val: BodyPortfolioModalData): void {
        this.body.append(`
        <h3 style="margin-bottom: 10px; text-align: center;">${val.subtitle || ''}</h3>
        <a class="fancybox" title="${this.portfolioModalData.title}" href="${val.src}" data-fancybox data-caption="">
            <img class="img-responsive" src="${val.src}" alt="${this.portfolioModalData.title}">  
        </a>
        <p style="padding-top: 10px;">${val.about}</p>
        <hr>
        `);
    }

    private appendVideo(val: BodyPortfolioModalData): void {
        this.body.append(`
        <h3 style="margin-bottom: 10px; text-align: center;">${val.subtitle || ''}</h3>
        <a class="fancybox" title="${this.portfolioModalData.title}" href="${val.src}" data-fancybox data-caption="">
        <div class="video-portfolio">
            <video controls="controls" muted poster="">
                <source src="${val.src}" type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'>
                Тег video не поддерживается вашим браузером. 
            </video>
        </div>  
        </a>
        <p style="padding-top: 10px;">${val.about}</p>
        <hr>
        `);
    }

}